<template>
  <div
    v-if="!isPageLoading"
    class="ScreeningsUpdateBuilder">
    <div class="NormalPageTitle">{{ mixWB('BUILDER') }}</div>
    <CloseButton />

    <BuilderForm
      :buttonText="next.text"
      :isLoading="isLoading"
      :startData="metaData.builder"
      @form-submit="onFormSubmit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BuilderForm from '@/components/Forms/BuilderForm.vue'
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'

export default {
  name: 'ScreeningsUpdateBuilder',
  data() {
    return {
      isPageLoading: true,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'projectType',
      'noOfBuildings',
      'noOfSelectedUnits',
      'metaData',
      'currentScreeningData',
      'currentScreeningTenderDetails',
      'currentScreeningLoaded',
    ]),
    next() {
      if (this.currentScreeningData.isStarted) {
        return {
          text: this.mixWB('SAVE'),
          path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.projectType) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateProjectDetails', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.noOfBuildings && !this.noOfSelectedUnits) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateBuildings', params: { screeningID: this.currentScreening.id } },
        }
      }
      if (!this.currentScreeningTenderDetails.selectedQuestionIDs.length) {
        return {
          text: this.mixWB('NEXT'),
          path: { name: 'ScreeningsUpdateTenderDetails', params: { screeningID: this.currentScreening.id } },
        }
      }
      return {
        text: this.mixWB('SAVE'),
        path: { name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } },
      }
    },
  },
  methods: {
    onFormSubmit(builderInfo) {
      this.isLoading = true

      this.$store.dispatch('setBuilderInfo', builderInfo)

      this.$router.push(this.next.path)
    },
  },
  components: {
    BuilderForm,
    CloseButton,
  },
  mounted() {
    const waitForLoad = setInterval(() => {
      if (!this.currentScreeningLoaded) {
        return
      }

      clearInterval(waitForLoad)
      this.isPageLoading = false
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateBuilder
    pageWrap()
</style>
